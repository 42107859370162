export default [
  {
    key: 'name',
    label: 'field.fullName',
    rules: 'required|max:100',
    type: 'text',
  },
  {
    key: 'phoneNumber',
    label: 'field.phoneNumber',
    rules: 'required|min:8|max:9',
    prefix: '+855',
    type: 'international-tel',
    cols: 6,
  },
  {
    key: 'password',
    name: 'new-password',
    label: 'field.password',
    rules: 'min:6|max:6',
    type: 'password',
    hideInView: true,
  },
  {
    key: 'retypePassword',
    label: 'field.retypePassword',
    rules: 'confirmed:password',
    type: 'password',
    hideInView: true,
  },
  {
    key: 'secondaryPhoneNumber',
    label: 'field.secondaryPhoneNumber',
    rules: 'min:8|max:9',
    prefix: '+855',
    type: 'international-tel',
    cols: 6,
  },
  {
    key: 'email',
    label: 'field.email',
    rules: 'required|email|max:50',
    type: 'text',
    cols: 6,
  },
  {
    key: 'companyName',
    label: 'field.companyName',
    rules: 'max:100',
    type: 'text',
  },
  {
    key: 'officeNo',
    label: 'field.officeNo',
    rules: 'max:100',
    type: 'text',
  },
  {
    key: 'businessRegisterNo',
    label: 'field.businessRegisterNo',
    rules: 'max:100',
    type: 'text',
  },
  {
    key: 'vat',
    label: 'field.vat',
    rules: 'max:100',
    type: 'text',
  },
  {
    key: 'provinceId',
    label: 'field.province',
    rules: '',
    type: 'asyn-single-selection',
    repository: 'province',
    selectionKey: 'id',
    selectionLabel: 'name',
    localization: true,
    clearable: true,
  },
  {
    key: 'districtId',
    label: 'field.district',
    rules: '',
    type: 'asyn-single-selection',
    repository: 'district',
    selectionKey: 'id',
    selectionLabel: 'name',
    localization: true,
    clearable: true,
  },
  {
    key: 'businessAddress',
    label: 'field.addressLine',
    rules: 'max:200',
    type: 'text',
  },
  {
    key: 'createdBy',
    label: 'field.bdPic',
    rules: '',
    type: 'asyn-single-selection',
    repository: 'user',
    selectionKey: 'id',
    selectionLabel: 'name',
  },
  {
    key: 'isEnable',
    label: 'field.active',
    rules: '',
    type: 'checkbox',
    cols: 12,
  },
  {
    key: 'note',
    label: 'field.remark',
    rules: 'max:200',
    type: 'textarea',
    cols: 12,
  },
  {
    key: 'documents',
    label: 'field.documentFiles',
    type: 'document-uploader',
    cols: 12,
  },
]
